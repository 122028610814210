import { Injectable , OnDestroy } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { shareReplay, map, switchMap } from 'rxjs/operators';
import { FirestoreService } from "@shared/services/firestore.service";
import { AuthService } from "./auth.service";

export interface Job {
  meta: {
    id: string
  }
  info: {
    project: string;
    firstName: string;
    lastName: string;
  };
}

interface JobInfo {
  project: string | null;
  firstName: string | null;
  lastName: string | null;
  isSystemWorking: boolean | null;
  address1: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phone: string | null;
  email: string | null;
}

interface JobSystem {
  outdoorUnit: string | null;
  indoorUnit: string | null;
  thermostat: string | null;
}

interface JobTemplateDescription {
  type: string | null;
}

interface JobPsisMeta {
  createdAt: string | null;
}

interface JobConfirmation {
  signedAt: string | null;
  prices: any; // Replace 'any' with a more specific type if possible
}

interface JobMeta {
  created: string | null;
  createdBy: string | null;
  id: string | null;
  jobsGroupId: string | null;
}

export interface CondensedJob {
  state: string;
  info: JobInfo | null;
  sent: boolean | null;
  system: JobSystem | null | '';
  customerSelectedSystem: string | null;
  template: string | null;
  templateDescription: JobTemplateDescription | {};
  psis: { meta: JobPsisMeta } | {};
  confirmation: JobConfirmation | {};
  deposits: boolean;
  stateLastUpdated: string | null;
  status: string | null;
  price: number | null;
  depositsTotal: number | null;
  balance: number | null;
  tonnage: number | null;
  notes: string | null;
  meta: JobMeta | null;
}

@Injectable({
  providedIn: "root"
})
export class JobsService implements OnDestroy {
  viewableJobs$: Observable<any[]>;
  viewableJobsCondensed$: Observable<CondensedJob[]>;
  jobsDeleted$: Observable<any[]>;

  constructor(
    public db: AngularFirestore,
    public database: FirestoreService,
    private authService : AuthService
  ) {

    this.viewableJobs$ = this.authService.user$.pipe(
      switchMap((user: any) => {
        if (user.tier == 2) {
          return this.database.col$('jobs', ref => ref
            .where('meta.created', '!=', '')
            .orderBy('meta.created', 'desc')
          ).pipe(
            map((jobs: any[]) => {
              return jobs.filter(job => job.meta.createdBy === user.uid);
            })
          );
        } else {
          return this.database.col$('jobs', ref => ref
            .where('meta.created', '!=', '')
            .orderBy('meta.created', 'desc')
          ).pipe(
            map((jobs: any[]) => {
              return jobs
            })
          );
        }

      }),
      shareReplay({bufferSize: 1, refCount: true}),
    )

    this.viewableJobsCondensed$ = this.viewableJobs$.pipe(
      map((documents: any[]) => {
        // Map Firestore documents to your desired format (selecting only certain fields)
        return documents.map((job: any) => ({
         info: {
          project: job?.info?.project,
          firstName: job?.info?.firstName,
          lastName: job?.info?.lastName,
          isSystemWorking: job?.info?.isSystemWorking,
          address1: job?.info?.address1,
          city: job?.info?.city,
          state: job?.info?.state,
          zip: job?.info?.zip,
          phone: job?.info?.phone,
          email: job?.info?.email,
         },
          sent: job?.sent,
          system: job?.system ? {
            outdoorUnit: job?.system?.outdoorUnit,
            indoorUnit: job?.system?.indoorUnit,
            thermostat: job?.system?.thermostat,
          } : '',
          customerSelectedSystem: job?.customerSelectedSystem,
          template: job?.template,
          templateDescription: job?.templateDescription ? {
            type : job?.templateDescription?.type
          } : {},
          psis: job?.psis ? {
            meta : {
                createdAt: job.psis.meta.createdAt
              }
          } : {},
          confirmation: job?.confirmation ? {
            'signedAt' : job?.confirmation?.signedAt,
            'prices' : job?.confirmation?.prices
          } : {},
          deposits: job?.deposits ?  true : false,
          state: job?.state,
          stateLastUpdated: job?.stateLastUpdated,
          status: job?.status,
          price: job?.price,
          depositsTotal: job?.depositsTotal,
          balance: job?.balance,
          tonnage: job?.tonnage,
          notes: job?.notes,
          reminder: job?.reminder,
          meta: {
            created: job?.meta?.created,
            createdBy: job?.meta?.createdBy,
            id: job?.meta?.id,
            jobsGroupId: job?.meta?.jobsGroupId,
            isAdditionalJob: job?.meta?.isAdditionalJob
          },
        }));
      })
    ),
    shareReplay({bufferSize: 1, refCount: true});

    this.jobsDeleted$ = this.database.col$('archivedJobs', ref => ref
      .orderBy('meta.created', 'desc')
      ), shareReplay({bufferSize: 1, refCount: true});

  }

  ngOnDestroy() {
  }

  public watchJob(id:string) {
    const job = this.database.doc$(`jobs/${id}`)
    if (job) {
      return job
    } else {
      return null
    }
  }

}
